

export default function SetCustomerData({ session, customer, placementIds = [] }){
    const placements = typeof placementIds === "string" ? placementIds.split(",") : placementIds;

    return `
        mutation {
            updateCustomer(
                by: BY_CID, 
                id: "${session}",
                 ${Object.keys(customer).length ? ` customer: {
                                                        firstName: "${customer['first_name']}",
                                                        lastName: "${customer['last_name']}",
                                                        customerReference: "${customer['customer_reference']}",
                                                        marketingPermission: false
                                                        }` 
                                                : "" }
            ) 
        }

    `
}