import { product } from "../fragments/product";


export default function SearchedFor({ session, query, id, placementIds = [], cart }){
    const placements = typeof placementIds === "string" ? placementIds.split(",") : placementIds

    return`
        mutation {
            updateSession(by: BY_CID, id: "${session}",
            params: {
                event: {
                    type: SEARCHED_FOR
                    target: "${query}"
                    ${id ? `ref: "${id}"` : ""}
            }
             ${(cart?.id) ? `cart: {
                    items: [
                        ${(cart.items).map(item => `{
                                                productId: "${item['productId']}",
                                                skuId: "${item['skuId']}",
                                                name: """${item['name']}""",
                                                unitPrice: ${item['unitPrice']},
                                                priceCurrencyCode: "${item['priceCurrencyCode']}",
                                                quantity: ${item['quantity']}
                                            }`
                        )}
                        ]
                }` : ""}
            }
            ) {
            pages {
                forSearchPage(
                    params: {
                        isPreview: false, 
                        imageVersion: VERSION_8_400_400,
                        slotIds: [${placements.map(id => `"${id}"`)}]
                    },
                    term: "${query}") {
                        resultId
                        primary {
                            ...product
                        }
                    }
                }
            }
        }

        ${product}
    `
}