/**
 * Nosto Graphql Service
 *  Playground: https://my.nosto.com/bigcommerce-r14v4z7cjw/graphql
 *  Docs: https://docs.nosto.com/techdocs/apis/graphql-an-introduction/graphql-using-queries
 * 
 * Using the header X-Nosto-Ignore: True will cause any traffic from being recorded. Queries and Mutations will work normally but any API calls containing this header will not be archived or accrue towards the statistics.
 */

import axios from "axios";


export default class NostoGraphql{
    constructor(){}

    async post(queryData){
        return await axios({
            url: "https://api.nosto.com/v1/graphql",
            method: "POST",
            data: queryData,
            headers: {
                'Content-Type': 'application/graphql',
                'Authorization': "Basic " + btoa(":"+process.env.NEXT_PUBLIC_nosto_app_token)
            }
        })
        .then(response => response?.data)
        .catch(err => console.log(err))   
    }


}