import { product } from "../fragments/product";


export default function ViewedCategory({ 
    session, 
    taxonomy, 
    target,
    placementIds = [],
    categoryNameTaxonomy,
    categoryTarget = null,
    cart
}){
    const categoryPath = (taxonomy.length === taxonomy.lastIndexOf("/") + 1) ? taxonomy.slice(0, -1) : "/" + taxonomy;
    const slotIds = Array.isArray(placementIds) ? placementIds : placementIds.split(",").map(id => `"${id.trim()}"`);

    return`
        mutation {
            updateSession(by: BY_CID, id: "${session}",
            params: {
                event: {
                    type: VIEWED_CATEGORY
                    target: "${categoryTarget ?? target ?? categoryPath}"
                }
                 ${(cart?.id) ? `cart: {
                    items: [
                        ${(cart.items).map(item => `{
                                                productId: "${item['productId']}",
                                                skuId: "${item['skuId']}",
                                                name: """${item['name']}""",
                                                unitPrice: ${item['unitPrice']},
                                                priceCurrencyCode: "${item['priceCurrencyCode']}",
                                                quantity: ${item['quantity']}
                                            }`
                        )}
                        ]
                }` : ""}
            }
            ) {
            pages {
                forCategoryPage (
                    params: {
                        isPreview: false, 
                        imageVersion: VERSION_8_400_400
                        slotIds: [${slotIds}]
                    },
                    category: "${categoryNameTaxonomy ?? taxonomy}") {
                        resultId
                        primary {
                            ...product
                        }
                    }
                }
            }
        }

        ${product}
    `
}

