import NostoGraphql from "./Graphql.service";
import SearchedFor from "./mutations/SearchedFor";
import ViewedCart from "./mutations/ViewedCart";
import ViewedCategory from "./mutations/ViewedCategory";
import ViewedFrontPage from "./mutations/ViewedFrontPage";
import ViewedProduct from "./mutations/ViewedProduct";
import SetCustomerData from "./mutations/SetCustomerData";
import ViewedPage from "./mutations/ViewedPage";
import ViewedNotFound from "./mutations/ViewedNotFound";
import ViewedBlog from "./mutations/ViewedBlog";
import ViewedOther from "./mutations/ViewedOther";


const nostoGraphql = new NostoGraphql()



/**
 * When viewing a home-page
 * @param {string} session 
 * @param {function} callback
 * @param {array} placementIds
 * @param {object} cart
 */

export async function ViewFrontPage({ 
    session, 
    callback = () => null, 
    placementIds = [],
    cart = cart?.items ?? [] 
}){
    const schema = ViewedFrontPage({ session, placementIds, cart  })

    await nostoGraphql.post(schema).then(response => {
        callback(response?.data?.updateSession?.pages?.forFrontPage)
    })
}




/**
 * When a visitor logs in customer information 
 * @param {string} session 
 * @param {object} customer
 */

export function SetCustomer({ 
     customer = {}, 
     session, 
     placementIds = [],
    // callback = () => null, 
    // cart
}) { 
    
    const schema = SetCustomerData({ session, customer, placementIds})

    nostoGraphql.post(schema).then(response => {
        // callback(response?.data?.updateSession?.pages?.forOtherPage)
    })
}





/**
 *  When viewing a product
 * @param {string} session 
 * @param {object} customer
 * @param {function} callback
 * @param {array} placementIds
 * @param {string} productId
 * @param {object} cart
 */

export async function ViewProduct({ 
    session, 
    productId = null, 
    resultId = "", 
    callback = () => null, 
    placementIds = [],
    cart = cart?.items ?? []
}){ 
    const schema = ViewedProduct({ session, productId, resultId, placementIds, cart })
    
    return await nostoGraphql.post(schema).then(response => {
        callback(response?.data?.updateSession?.pages?.forProductPage)
    })
}




/**
 * When viewing a general page
 * @param {string} session 
 * @param {function} callback
 * @param {array} placementIds
 * @param {string} url
 * @param {object} cart
 */

export function ViewPage({ 
    session,  
    url, 
    callback = () => null, 
    placementIds = [],
    cart = cart?.items ?? []
}){
    const schema = ViewedPage({ session, placementIds, url, cart  })

    nostoGraphql.post(schema).then(response => {
        // console.log(response?.data?.updateSession?.pages?.forGeneralLayoutPage)
        callback(response?.data?.updateSession?.pages?.forGeneralLayoutPage)
    })
}



/**
 * When viewing a general page
 * @param {string} session 
 * @param {function} callback
 * @param {array} placementIds
 * @param {string} url
 * @param {object} cart
 */

export function ViewOther({ 
    session,  
    url, 
    callback = () => null, 
    placementIds = [],
    cart = cart?.items ?? []
}){
    const schema = ViewedOther({ session, placementIds, url, cart  })

    nostoGraphql.post(schema).then(response => {
        // console.log(response?.data?.updateSession?.pages?.forGeneralLayoutPage)
        callback(response?.data?.updateSession?.pages?.forGeneralLayoutPage)
    })
}






/**
 * All Blogs 
 * @param {string} session 
 * @param {function} callback
 * @param {array} placementIds
 * @param {string} url
 * @param {object} cart
 */

export function ViewBlog({ 
    session, 
    url, 
    callback = () => null, 
    placementIds = [],
    cart = cart?.items ?? []
}){
    const schema = ViewedBlog({ session, placementIds, url, cart  })

    nostoGraphql.post(schema).then(response => {
        // console.log(response?.data?.updateSession?.pages?.forLandingPage)
        callback(response?.data?.updateSession?.pages?.forLandingPage)
    })
}





/**
 * When viewing a category or collection,
 * @param {string} session 
 * @param {function} callback
 * @param {array} placementIds
 * @param {string} taxonomy
 * @param {string} categoryTarget
 * @param {object} cart
 */

export function ViewCategory({ 
    session, 
    taxonomy = "", 
    callback = () => null, 
    placementIds = [],
    categoryTarget,
    target,
    categoryNameTaxonomy,
    cart = cart?.items ?? []
}){
    const schema = ViewedCategory({ session, taxonomy, placementIds, categoryTarget, target, categoryNameTaxonomy, cart })

    nostoGraphql.post(schema).then(response => {
        // console.log(response?.data?.updateSession?.pages?.forCategoryPage)
        callback(response?.data?.updateSession?.pages?.forCategoryPage)
    })
}





/**
 * When viewing the results of a search
 * @param {string} session 
 * @param {function} callback
 * @param {array} placementIds
 * @param {string} query
 * @param {object} cart
 */

export function ViewSearch({ 
    session, 
    query = "", 
    callback = () => null, 
    placementIds = [],
    categoryTarget,
    cart = cart?.items ?? []
}){
    const schema = SearchedFor({ session, query, placementIds, categoryTarget, cart })

    nostoGraphql.post(schema).then(response => {
        // console.log(response?.data?.updateSession?.pages?.forSearchPage)
        callback(response?.data?.updateSession?.pages?.forSearchPage)
    })
}





/**
 *When viewing a checkout page
 * @param {string} session 
 * @param {function} callback
 * @param {array} placementIds
 * @param {object} cart
 */

export function ViewCart({ 
    session, 
    callback = () => null, 
    placementIds = [],
    cart
}){

    if( cart?.id ){
        const schema = ViewedCart({ 
            session, 
            placementIds, 
            id: cart?.id, 
            cartValue: cart?.cart_amount,
            items:cart?.items
        })
        
        nostoGraphql.post(schema).then(response => {
            callback(response?.data?.updateSession?.pages?.forCartPage)
        })
    }
}




/**
 * Set nosto cart for recommendations
 * @param {string} session 
 * @param {function} callback
 * @param {array} placementIds
 * @param {array} items
 * @param {object} cart
 */

export function SetCart({ 
    session, 
    id,
    items = [], 
    callback = () => null, 
    cartValue,
    placementIds = []
}){
    if( session ){
        const schema = ViewedCart({ session, placementIds, items, id, cartValue })

        nostoGraphql.post(schema).then(response => {
            // callback(response?.data?.updateSession?.pages?.forCartPage)
        })
    }
}





/**
 * When viewing a 404
 * @param {string} session 
 * @param {function} callback
 * @param {array} placementIds
 * @param {object} cart
 */

export function View404({ 
    session, 
    callback = () => null, 
    placementIds = [],
    cart = cart?.items ?? []
}){
    const schema = ViewedNotFound({ session, placementIds, cart })

    nostoGraphql.post(schema).then(response => {
        // console.log(response?.data?.updateSession?.pages?.forNotFoundPage)
        callback(response?.data?.updateSession?.pages?.forNotFoundPage)
    })
}