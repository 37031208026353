import { product } from "../fragments/product";


export default function ViewedCart({ session, id, cartValue, placementIds = [], items }){
    const placements = typeof placementIds === "string" ? placementIds.split(",") : placementIds

    return`
        mutation {
            updateSession(by: BY_CID, id: "${session}",
            params: {
                event: {
                    type: VIEWED_PAGE
                    target: "${process.env.NEXT_PUBLIC_siteUrl}/cart"
                    ${id ? `ref: "${id}"` : ""}
                }
                ${items.length ? `cart: {
                    items: [
                        ${items.map(item => `{
                                                productId: "${item['productId']}",
                                                skuId: "${item['skuId']}",
                                                name: """${item['name']}""",
                                                unitPrice: ${item['unitPrice']},
                                                priceCurrencyCode: "${item['priceCurrencyCode']}",
                                                quantity: ${item['quantity']}
                                            }`
                        )}
                        ]
                }` : ""}
            }
            ) {
            pages {
                forCartPage(
                    params: {
                        isPreview: false, 
                        imageVersion: VERSION_8_400_400
                        slotIds: [${placements.map(id => `"${id}"`)}]
                    },
                    value: ${cartValue}) {
                        resultId
                        primary {
                            ...product
                        }
                    }
                }
            }
        }

        ${product}
    `
}