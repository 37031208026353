import { product } from "../fragments/product";


export default function ViewedProduct({ session, productId, resultId, cart }){
    return`
        mutation {
            updateSession(by: BY_CID, id: "${session}",
            params: {
                event: {
                    type: VIEWED_PRODUCT
                    target: "${productId}"
                    ${resultId ? `ref: "${resultId}"` : ""}
                }
                ${(cart?.id) ? `cart: {
                    items: [
                        ${(cart.items).map(item => `{
                                                productId: "${item['productId']}",
                                                skuId: "${item['skuId']}",
                                                name: """${item['name']}""",
                                                unitPrice: ${item['unitPrice']},
                                                priceCurrencyCode: "${item['priceCurrencyCode']}",
                                                quantity: ${item['quantity']}
                                            }`
                        )}
                        ]
                }` : ""}
            }
            ) {
            pages {
                forProductPage(
                    params: {
                        isPreview: false, 
                        imageVersion: VERSION_8_400_400
                    },
                    product: "${productId}") {
                        resultId
                        primary {
                            ...product
                        }
                    }
                }
            }
        }

        ${product}
    `
}